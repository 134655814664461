<template>
  <rpl-text-link v-bind="$attrs" class="text-link" />
</template>

<script>
import { RplTextLink } from '@dpc-sdp/ripple-link';

export default {
  name: 'TextIconLink',
  components: {
    RplTextLink,
  },
};
</script>
<style lang="scss" scoped>
.text-link :deep(.rpl-text-icon__group) {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
</style>
