import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import dashboard from '@/store/modules/dashboard';
import page from '@/store/modules/page';
import breadcrumb from '@/store/modules/breadcrumb';
import table from '@/store/modules/table';
import tenants from '@/store/modules/tenants';
import download from '@/store/modules/download';
import colourRegistry from '@/store/modules/colourRegistry';
import chart from '@/store/modules/chart';
import admin from '@/store/modules/admin';
import data from '@/store/modules/data';
import user from './modules/user';
import upload from './modules/upload';
import batch from './modules/batch';
import apiCalls from './modules/apiCalls';
import group from './modules/group';
import { RootState, CoreState } from './types';
import reports from './modules/reports';
import resourceAttachment from './modules/resourceAttachment';
import resource from './modules/resource';

Vue.use(Vuex);

const defaultState: CoreState = {
  siteName: '',
  headerText: undefined,
  subText: undefined,
};

export const storeOptions: StoreOptions<RootState> = {
  state: () => ({ ...defaultState } as RootState), // Type assertion stops Typescript complaining about the modules
  getters: {
    headerText: (state) => state.headerText,
    subText: (state) => state.subText,
    siteName: (state) => state.siteName,
  },
  mutations: {
    setHeaderText(state, text) {
      state.headerText = text;
    },
    setSubText(state, text) {
      state.subText = text;
    },
    setSiteName(state, siteName) {
      state.siteName = siteName;
    },
  },
  modules: {
    apiCalls,
    admin,
    user,
    dashboard,
    page,
    upload,
    uploadDelete: upload,
    download,
    table,
    group,
    tenants,
    breadcrumb,
    batch,
    reports,
    resourceAttachment,
    colourRegistry,
    chart,
    resource,
    data,
  },
};

export default new Vuex.Store<RootState>(storeOptions);
