
import Vue from 'vue';

import { RplBaseLayout, RplPageLayout } from '@dpc-sdp/ripple-layout';
import RplSiteHeader from '@dpc-sdp/ripple-site-header';
import authFactory from '@/api/auth';
import defaultGetConfig from '@/config';
import { defaultLogo } from '@/themes';
import { RplImage } from '@/models/site.model';
import HeaderGraphic from '@/components/HeaderGraphic.vue';
import Notice from '@/components/Notice.vue';
import HeroBanner from '@/components/ripple/HeroBanner.vue';
import BreadCrumb from '@/components/ripple/BreadCrumb.vue';
import { paths } from '@/router/paths';
import { DataFromApi, getData, hasData } from '@/api/data';
import { Dashboard } from '@/models/dashboard.model';
import Footer from './components/footer/Footer.vue';
import './themes/global.scss';
import './themes/formio.scss';

interface Data {
  breakpoint: number;
  homeUrl: string;
}

interface Computed {
  links: { text: string; url?: string }[];
  userDisplayName: string;
  showErrors: boolean;
  logo: RplImage;
  title: string;
  dashboardCode?: string;
  loggedIn: boolean;
  headerBgColour: string;
}

type Methods = {
  routeHeaderClicks: (evt: MouseEvent) => void;
};
type Props = unknown;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'App',
  components: {
    RplBaseLayout,
    RplSiteHeader,
    RplPageLayout,
    Notice,
    BreadCrumb,
    HeroBanner,
    Footer,
    HeaderGraphic,
  },

  data() {
    return {
      breakpoint: 992,
      homeUrl: '',
    };
  },
  computed: {
    links() {
      if (this.loggedIn) {
        const links: {
          text: string;
          children: { text: string; url: string }[];
        } = {
          text: this.userDisplayName,
          children: [],
        };
        if (this.dashboardCode) {
          links.children.push({
            text: 'Update profile',
            url: paths.dashboard(this.dashboardCode) + paths.profile(),
          });
          links.children.push({
            text: 'Manage groups',
            url: paths.dashboard(this.dashboardCode) + paths.groups(),
          });
          links.children.push({
            text: 'Change password',
            url: paths.dashboard(this.dashboardCode) + paths.changePassword(),
          });
          links.children.push({
            text: 'Manage two-step verification',
            url: paths.dashboard(this.dashboardCode) + paths.mfa(),
          });
        }
        links.children.push({
          text: 'Sign out',
          url: this.dashboardCode
            ? `/${this.dashboardCode}${paths.logout()}`
            : paths.logout(),
        });
        return [
          {
            text: 'Home',
            url: this.homeUrl,
          },
          links,
        ];
      }
      return [
        {
          text: 'Sign in',
          url: this.$router.resolve({ name: 'login', query: this.$route.query })
            .route.fullPath,
        },
      ];
    },
    userDisplayName() {
      return this.$store.getters.userDisplayName;
    },
    showErrors() {
      return false; // this.$store.state.feature.showErrors;
    },
    logo() {
      return defaultLogo;
    },
    title() {
      if (this.$store.state.breadcrumb.breadcrumbs.length > 0) {
        return this.$store.state.breadcrumb.breadcrumbs[0].text;
      }
      return this.$store.state.breadcrumb.root?.text;
    },
    dashboardCode() {
      const { dashboardFromApi }: { dashboardFromApi: DataFromApi<Dashboard> } =
        this.$store.state.dashboard;
      return hasData(dashboardFromApi)
        ? getData(dashboardFromApi).code
        : undefined;
    },
    loggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    headerBgColour() {
      return this.$store.getters.headerBgColour || 'var(--primary)';
    },
  },
  methods: {
    routeHeaderClicks(evt) {
      const target = evt.target as HTMLElement;
      if (target.tagName.toLowerCase() === 'a') {
        const anchor = target as HTMLAnchorElement;
        const href = anchor.getAttribute('href');
        if (href?.startsWith('/')) {
          evt.preventDefault();
          this.$router.push({ path: href });
        }
      }
    },
  },
  mounted() {
    authFactory()
      .then((authService) => authService.getUser())
      .then((user) => {
        if (user) {
          this.$store.commit('setUser', user);
        }
      });
  },
  created() {
    defaultGetConfig().then((c) => {
      this.homeUrl = c.cognito.authService;
    });
  },
  watch: {
    title(title) {
      document.title = title;
    },
  },
});
