import { VueComponent } from '@/types';

export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum ColumnDataType {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  DateTime = 'DateTime',
  FileSize = 'FileSize',
  Action = 'Action',
  Component = 'Component',
}

export interface SortOption {
  name: string;
  id: string;
  direction: keyof typeof SortDirection;
  columnKey: string;
}

export interface RowViewModel {
  row: Row;
  data?: unknown;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  callBackFunction?: (data: any) => void;
}

export interface TableViewModel {
  config: TableMetadata;
  rows: RowViewModel[];
  viewPath?: (row: RowViewModel) => string;
}

export interface Column {
  label: string;
  key: string;
  dataType: ColumnDataType;
  sortable: boolean;
  filterable: boolean;
  searchable: boolean;
  summable?: boolean;
  hidden?: boolean;
  actions: Array<string>;
  dynamic?: boolean; // if the value is calculated on the frontend
  value?: string | Record<string, unknown>; // this would be a json path or json-logic to calculate the value
  component?: string; // component to render if ColumnDataType.Component
  props?: unknown; // props to pass to the component
  multiFilterable?: boolean;
}

export type UrlColumn = Column & { url?: string };

export interface TableMetadata {
  label: string;
  defaultSortOption?: {
    key: string;
    direction: 'Ascending' | 'Descending';
  };
  columns: Column[];
  pageSize: number;
  hideSpin?: boolean;
  export?: boolean;
  allowColumnConfig?: boolean;
  retainSession?: boolean;
  retainLocal?: boolean;
  rowRef?: string;
}

export type GenericTableMetadata = TableMetadata & {
  rowsPath?: string;
  hideSearchHeading?: boolean;
  allowMultiSelect?: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  rowConfig?: any;
};

export interface ColumnData {
  key: string;
  value?: string;
  actions?: string[];
  component?: string;
}

export interface Row {
  id: string;
  columns: ColumnData[];
  config?: Record<string, unknown>;
}

export interface Table {
  tableMetadata: TableMetadata;
  tableContents: Row[];
}

export interface TableComponent {
  name: string;
  component: VueComponent;
}

export interface TabViewModel {
  label: string;
  key: string;
  icon?: string;
}

export type FilterModel = Record<string, string | string[] | undefined>;

export interface ColumnFilter {
  column: Column;
  filterValue: string | string[];
}
